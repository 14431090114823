import { memo, useMemo } from 'react';

import * as Meta from '@Queries/Meta';
import MainSelect from '@UI/MainSelect';

import type { SizesSelectProps } from '@UI/SizesSelect';
import type { ProductData } from '@Types/Product';
import type { FC } from 'react';

export interface ProductSelectProps extends SizesSelectProps {
  product: ProductData;
}

const ProductSelect: FC<ProductSelectProps> = (props) => {
  const { product, onClickItem, ...restProps } = props;
  const meta = Meta.useMeta();
  const currency = meta.data?.currency;

  const sizeItems = useMemo(() => {
    return product.variants.values.map((variant, index: number) => {
      const sizes = [];
      const value = product.parameterValues.find((val) => val.parameterId === variant.items[0].id);
      const unit = product.units.find((u) => value && u.id === value.unitId);
      const price = variant.price && {
        value: variant.price,
        currency,
      };
      const title = `${variant.parameters}${unit?.title ? unit.title : ''}`;

      variant.items?.forEach((item) => {
        const parameter = product.parameters.find((p) => p.id === item.id);

        if (parameter) {
          sizes.push({ title: parameter.title, value: Math.round(+item.value) });
        }
      });

      return {
        sizes,
        id: variant.id.toString(),
        title,
        name: title,
        href: variant.link,
        price,
        selected: index === 0,
        isActive: product.isActive,
      };
    });
  }, [currency, product]);

  return (
    <MainSelect
      {...restProps}
      title={product.variants.title}
      items={sizeItems}
      disabled={sizeItems.length < 2}
      onClickItem={onClickItem}
    />
  );
};

export default memo(ProductSelect);
