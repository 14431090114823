import { memo, useCallback, useRef, useState } from 'react';

import useDeps from '@Contexts/DI/useDeps';
import ApiProduct from '@Api/Product';
import ReadySolutions from './ReadySolutions';

import type { FC } from 'react';
import type { ProductData, ReadySolutionsData } from '@Types/Product';
import type { ReadySolutionsProps } from './ReadySolutions';

export interface ReadySolutionsContainerProps extends Omit<ReadySolutionsProps, 'onChangeProduct'> {
  className?: string;
  readySolutions: ReadySolutionsData;
  slug?: string;
  onChangeProduct?: (product: ProductData) => void;
}

const ReadySolutionsContainer: FC<ReadySolutionsContainerProps> = (props) => {
  const { onChangeProduct, readySolutions, slug, ...restProps } = props;
  const { logger } = useDeps();
  const [readySolutionsVariants, setReadySolutionsVariants] = useState<ProductData[]>([]);
  const abortController = useRef<AbortController>(null);

  const handleChangeProduct = useCallback(
    (newProductId: number) => {
      const newProduct = readySolutionsVariants.find((variant) => variant.id === newProductId);

      if (!newProduct) return;

      if (onChangeProduct) onChangeProduct(newProduct);
    },
    [readySolutionsVariants, onChangeProduct],
  );

  const handleMouseEnter = useCallback(async () => {
    if (readySolutions?.colors.length === 0) return;
    if (readySolutionsVariants.length > 0) return;

    const ids = [];
    readySolutions?.colors.forEach((color) => {
      ids.push(color.shopProductId);
    });

    if (ids.length === 0) return;

    try {
      // Отменяем предыдущий запрос
      if (abortController.current) {
        abortController.current.abort('abort previous request');
      }

      abortController.current = new AbortController();
      const products = await ApiProduct.getProductVariantInfo(
        { ids, slug },
        { signal: abortController.current.signal },
      );
      setReadySolutionsVariants(products);
    } catch (err) {
      // Запрос был прерван нами, ошибкой не считаем
      if (err.name === 'AbortError') return;

      logger.log(err);
    }
  }, [slug, readySolutions, readySolutionsVariants, logger]);

  return (
    <ReadySolutions
      {...restProps}
      readySolutions={readySolutions}
      onChangeProduct={handleChangeProduct}
      onMouseEnter={handleMouseEnter}
    />
  );
};

export default memo(ReadySolutionsContainer);
