import { memo, useMemo } from 'react';

import BuyButton from '@Components/BuyButton';
import useRenderType from '@Hooks/useRenderType';
import useHasInCart from '@Queries/Cart/useHasInCart';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface SEOBuyButtonProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
}

/**
 * NOTE:
 * Требование SEO чтобы от сервера всегда приходила кнопка покупки,
 * и только на клиенте скрывалась, если товар не в наличии
 */
const SEOBuyButton: FC<SEOBuyButtonProps> = (props) => {
  const { product, ...restProps } = props;
  const { isSSR, isCSR } = useRenderType();
  const hasInCart = useHasInCart({ productId: product.id });

  const needBuyButton = useMemo(() => {
    if (isSSR) return true;
    if (isCSR && product.isActive) return true;

    return false;
  }, [product.isActive, isCSR, isSSR]);

  if (!needBuyButton) return null;

  return <BuyButton {...restProps} href={product.link} hasInCart={hasInCart} />;
};

export default memo(SEOBuyButton);
