import { useMemo } from 'react';
import { useMedias } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import useCountries from '@Hooks/useCountries';
import YandexSplitCreditTab from '../YandexSplitCreditTab';
import CreditTab from '@Components/CreditTab';

import type { FC, HTMLAttributes } from 'react';
import type { CreditData } from '@Types/Product';

export interface CreditTabWrapperProps extends HTMLAttributes<HTMLDivElement> {
  mainCredit: CreditData;
}

const CreditTabWrapper: FC<CreditTabWrapperProps> = (props) => {
  const { mainCredit, ...restProps } = props;
  const { isMobileM, isMobileXS } = useMedias();
  const { t } = useTranslation();
  const { isBlr } = useCountries();
  const isYandexSplit = mainCredit?.type === 'yandexSplit';

  const creditTabText = useMemo(() => {
    if (!mainCredit) return '';

    if (mainCredit.type === 'halvaPartial') {
      return isMobileM ? t('ui.for') : '';
    }

    if (isBlr) {
      return isMobileXS ? t('ui.range.from') : t('ui.installment-from');
    }

    return t('ui.range.from');
  }, [mainCredit, isBlr, t, isMobileM, isMobileXS]);

  return isYandexSplit ? (
    <YandexSplitCreditTab {...restProps} credit={mainCredit} />
  ) : (
    <CreditTab
      {...restProps}
      credit={mainCredit}
      text={isYandexSplit ? '' : creditTabText}
      textEnd={mainCredit?.type === 'halvaPartial' ? ' x 4' : mainCredit?.prefix}
      view='catalog'
    />
  );
};

export default CreditTabWrapper;
