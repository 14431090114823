import { memo } from 'react';
import { Discount, cn, Price } from '@divlab/divanui';

import styles from './HiddenPrice.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface HiddenPriceProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  actualPrice: number;
  mini?: boolean;
}

const HiddenPrice: FC<HiddenPriceProps> = (props) => {
  const { className, actualPrice, mini, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.hiddenPrice, { [styles.mini]: mini }, className)}>
      <div className={styles.container}>
        <span className={styles.label}>Цена по акции</span>
        <Discount hiddenPrice className={styles.discount} />
      </div>
      <Price price={actualPrice} className={styles.actualPrice} />
    </div>
  );
};

export default memo(HiddenPrice);
